<template>
  <div class="attention-container">
    <van-cell
      v-for="collect in collectList"
      :key="collect.id"
      @click="toDetail(collect.id)"
      :title="collect.name"
      is-link
    />
    <Queries ref="queries" />
    <Attention ref="attention" />
  </div>
</template>

<script>
import { getCollectList } from "@/api/collect";
import Queries from '@/components/Queries'
import Attention from '@/components/Attention'

export default {
  components: { Queries, Attention },
  data() {
    return {
      page: {
        page: 1,
        pagesize: 20
      },
      collectList: [], // 收藏列表
      userInfo: {}
    }
  },
  methods: {
    // 药品详情
    toDetail(id) {
      if (this.userInfo.view === 0) {
        return this.$refs.queries.queriesShow = true
      }
      this.$router.push({
        path: '/detail',
        query: {
          id
        }
      })
    },
    async getCollectList() {
      try {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { data } = await getCollectList({ user_id: this.userInfo.id, ...this.page })
        this.collectList = data.data.reverse()
      } catch (error) {
        
        if(error.response.data.msg == '请先关注') this.$refs.attention.attentionShow = true
      }

    }
  },
  created() {
    this.getCollectList()
  }

}
</script>

<style lang="less" scoped>
</style>